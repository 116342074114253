<template>
  <form
    id="cusForm"
    v-if="customer"
    action
    data-vv-scope="customer-form"
    @submit.prevent="validateForm('customer-form')"
  >
    <base-material-alert v-if="userProcessed" color="success" dark>{{
      $t(text_processed)
    }}</base-material-alert>
    <users-form
      class="inputs"
      ref="user_form"
      :user="customer.user"
      :skip_validate="skip_validate"
      :buttons="false"
      :view="view"
      :customer_settings="customer_settings"
    >
      <template v-slot:row1>
        <v-col
          class="one-line"
          style="margin-top: -12px"
          cols="12"
          sm="6"
          md=""
          v-if="!reorder && customer_settings.date_of_birth.view"
        >
          <label>{{ $t("customers.birthdate") }}</label>
          <v-menu
            ref="menu"
            tabindex="13"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290px"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                outlined
                block
                v-validate="
                  skip_validate || !customer_settings.date_of_birth.required
                    ? ''
                    : 'required'
                "
                data-vv-name="birthdate"
                dense
                hide-details
                class="inputs pointer"
                v-model="computedDateFormatted"
                color="white"
                :error-messages="errors.collect('customer-form.birthdate')"
                readonly
                v-on="on"
              />
            </template>

            <v-date-picker
              first-day-of-week="1"
              v-model="customer.birthdate"
              color="primary"
              ref="picker"
              data-vv-name="birthdate"
              class="inputs"
              scrollable
              :show-current="today"
              no-title
              @input="menu = false"
              :max="today"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </template>
    </users-form>
    <v-row>
      <v-col
        class="py-0"
        cols="12"
        sm="6"
        md="2"
        v-if="customer_settings.height.view"
      >
        <label>{{ $t("customers.height") }}</label>
        <v-text-field
          outlined
          hide-details
          dense
          type="number"
          v-on:keypress="isNumber($event)"
          step="0.01"
          v-model="customer.height"
          data-vv-validate-on="blur"
          v-validate="
            !customer_settings.height.required
              ? 'between:1,2.5|max:4'
              : 'between:1,2.5|max:4|required'
          "
          class="inputs"
          :error-messages="errors.collect('customer-form.height')"
          color="secondary"
          data-vv-name="height"
          hint="Ejemplo: 1.75"
        />
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        sm="6"
        md="2"
        v-if="customer_settings.weight.view"
      >
        <label>{{ $t("customers.weight") }}</label>

        <v-text-field
          outlined
          hide-details
          dense
          step=".01"
          type="number"
          v-on:keypress="isNumber($event)"
          v-model="customer.weight"
          class="inputs"
          hint="Ejemplo: 72.8"
          data-vv-validate-on="blur"
          v-validate="
            !customer_settings.weight.required
              ? 'between:1,200|max:6'
              : 'between:1,200|max:6|required'
          "
          :error-messages="errors.collect('customer-form.weight')"
          color="secondary"
          data-vv-name="weight"
        />
      </v-col>
      <v-col
        class="py-0 pr-0"
        cols="12"
        md="3"
        v-if="customer_settings.allergies.view"
      >
        <label>{{ $t("customers.allergies") }}</label>
        <v-row style="width: 100%">
          <v-col class="py-0 pr-0" cols="4" sm="2" md="5">
            <v-select
              :items="options"
              single-line
              dense
              outlined
              hide-details
              v-validate="
                skip_validate || !customer_settings.allergies.required
                  ? ''
                  : 'required'
              "
              class="inputs"
              data-vv-name="options"
              :error-messages="errors.collect('customer-form.options')"
              v-model="allergies"
            ></v-select>
          </v-col>
          <v-col class="py-0 pr-0" cols="8" sm="10" md="7">
            <v-text-field
              :disabled="allergies == 0 || allergies == null"
              outlined
              dense
              class="inputs"
              v-model="customer.allergies"
              data-vv-validate-on="blur"
              hide-details
              v-validate="
                !customer_settings.allergies.required ||
                allergies == 0 ||
                allergies == null
                  ? 'min:2'
                  : 'required|min:2'
              "
              :error-messages="errors.collect('customer-form.allergies')"
              color="secondary"
              data-vv-name="allergies"
            />
          </v-col>
        </v-row>
      </v-col>
      <v-col
        class="py-0"
        cols="12"
        sm="6"
        md="3"
        v-if="customer_settings.date_of_birth.view"
      >
        <label>{{ $t("customers.birthdate") }}</label>

        <v-menu
          ref="menu"
          tabindex="13"
          hide-details
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
          offset-y
          v-if="customer_settings.date_of_birth.view"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              outlined
              block
              v-validate="
                skip_validate || !customer_settings.date_of_birth.required
                  ? ''
                  : 'required'
              "
              data-vv-name="birthdate"
              dense
              hide-details
              class="inputs pointer"
              v-model="computedDateFormatted"
              color="white"
              :error-messages="errors.collect('customer-form.birthdate')"
              readonly
              v-on="on"
            />
          </template>

          <v-date-picker
            first-day-of-week="1"
            v-model="customer.birthdate"
            hide-details
            color="primary"
            ref="picker"
            data-vv-name="birthdate"
            class="inputs"
            scrollable
            :show-current="today"
            no-title
            @input="menu = false"
            :max="today"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        class="py-0 mt-sm-0 mb-sm-0"
        cols="12"
        sm="6"
        md="2"
        v-if="customer_settings.nativeCity.view"
      >
        <label>{{ $t("customers.city") }}</label>
        <city-select
          class="inputs"
          :value="customer"
          valueKey="city"
          :validate="!customer_settings.nativeCity.required ? '' : 'required'"
          :error_messages="errors.collect('customer-form.city')"
          vv_name="city"
        />
      </v-col>

      <slot></slot>
      <v-col
        cols="12"
        md="6"
        v-if="
          $route.name === 'customerEdit' ||
            $route.name === 'customerCreateWithoutDate'
        "
        class="py-0 mt-5 mt-md-0"
      >
        <label v-text="$t('inventorys.description')"></label>
        <v-textarea
          class="mt-n2"
          rows="3"
          style="border-radius: 10px"
          outlined
          hide-details
          v-model="customer.description"
        >
        </v-textarea>
      </v-col>
    </v-row>
    <div class="pa-3 text-right" v-if="buttons !== false">
      <v-btn
        outlined
        @click="$router.back()"
        style="height: 25px"
        class="mr-3"
        elevation="0"
        >{{ $t("cancel") }}</v-btn
      >

      <v-btn
        style="
          width: 100px !important;
          color: #363533;
          height: 25px;
          padding-top: 10px !important;
        "
        elevation="0"
        type="submit"
      >
        <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
        {{ $t("save", { name: $t("") }) }}
      </v-btn>
    </div>
  </form>
</template>

<script>
import * as moment from "moment";
export default {
  name: "CustomersForm",
  components: {
    Timetable: () => import("@/components/ui/Timetable"),
    CountrySelect: () => import("@/components/ui/CountrySelect"),
    CitySelect: () => import("@/components/ui/CitySelect"),
    UsersForm: () => import("@/components/user/Form"),
  },

  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
    "customer.user.first_name": {
      handler() {
        this.generateToken();
      },
      deep: true,
    },
    "customer.user.last_name": {
      handler() {
        this.generateToken();
      },
      deep: true,
    },
  },
  props: {
    title: {},
    customer: {},
    buttons: {},
    view: {},
    edit: {},
    skip_validate: {},
    customer_settings: {
      type: Object,
      default: () => ({
        first_name: {
          view: true,
          required: true,
        },
        last_name: {
          view: true,
          required: true,
        },
        email: {
          view: true,
          required: true,
        },
        phone: {
          view: true,
          required: false,
        },
        date_of_birth: {
          view: true,
          required: false,
        },
        height: {
          view: true,
          required: false,
        },
        weight: {
          view: true,
          required: false,
        },
        allergies: {
          view: true,
          required: false,
        },
        nativeCity: {
          view: true,
          required: false,
        },
        meet: {
          view: true,
          required: false,
        },
      }),
    },
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");

    if (this.edit == true) {
      if (
        this.customer.allergies === null ||
        this.customer.allergies === undefined
      )
        this.allergies = 0;
      else this.allergies = 1;
    }
    console.log(this.view);
  },
  data() {
    return {
      userProcessed: false,
      menu: false,
      allergies: null,
      today: null,
      genders: [
        { text: this.$t("gender.m"), value: "m" },
        { text: this.$t("gender.f"), value: "f" },
      ],
      options: [
        { text: this.$t("yes"), value: 1 },
        { text: this.$t("no"), value: 0 },
      ],
    };
  },
  methods: {
    isNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[0-9.]+$/.test(char)) return true;
      else e.preventDefault();
    },
    customerAllergies() {
      if (
        this.customer.allergies === null ||
        this.customer.allergies === undefined
      )
        this.allergies = 0;
      else this.allergies = 1;
    },
    generateToken() {
      if (this.customer.user.first_name && this.customer.user.last_name) {
        let token =
          this.customer.user.first_name + "_" + this.customer.user.last_name;

        token = token.toLowerCase().replace(/ /g, "_");

        this.customer.token = token;
      }
    },

    async validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;

      //console.log("validating");
      console.log(this.customer.city);
      if (this.customer.city && typeof this.customer.city === "object")
        this.customer.city = this.customer.city.nm;

      let user_val = await this.$refs.user_form.validateForm("user-form");
      let form_val = await this.$validator.validateAll(scope);

      if (form_val && user_val) {
        this.$emit("validated", this.customer);
        //console.log("validated");
        return true;
      }
      return false;
    },
  },
  computed: {
    computedDateFormatted() {
      if (!this.customer.birthdate) return null;
      const [year, month, day] = this.customer.birthdate.split("-");
      return `${day}/${month}/${year}`;
    },
    URL() {
      return window.location.origin;
    },
    reorder() {
      return this.$route.name === "customerCreateWithoutDate" || "customerEdit";
    },
  },
};
</script>
<style lang="sass">
.inputs .v-input__slot
  margin-top: -10px

#cusForm
  .v-text-field__details
    display: flex !important
  label
    display: block
    height: 35px !important
  .one-line
    white-space: nowrap !important
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.customer)?_c('form',{attrs:{"id":"cusForm","action":"","data-vv-scope":"customer-form"},on:{"submit":function($event){$event.preventDefault();return _vm.validateForm('customer-form')}}},[(_vm.userProcessed)?_c('base-material-alert',{attrs:{"color":"success","dark":""}},[_vm._v(_vm._s(_vm.$t(_vm.text_processed)))]):_vm._e(),_c('users-form',{ref:"user_form",staticClass:"inputs",attrs:{"user":_vm.customer.user,"skip_validate":_vm.skip_validate,"buttons":false,"view":_vm.view,"customer_settings":_vm.customer_settings},scopedSlots:_vm._u([{key:"row1",fn:function(){return [(!_vm.reorder && _vm.customer_settings.date_of_birth.view)?_c('v-col',{staticClass:"one-line",staticStyle:{"margin-top":"-12px"},attrs:{"cols":"12","sm":"6","md":""}},[_c('label',[_vm._v(_vm._s(_vm.$t("customers.birthdate")))]),_c('v-menu',{ref:"menu",attrs:{"tabindex":"13","close-on-content-click":false,"transition":"scale-transition","min-width":"290px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({directives:[{name:"validate",rawName:"v-validate",value:(
                _vm.skip_validate || !_vm.customer_settings.date_of_birth.required
                  ? ''
                  : 'required'
              ),expression:"\n                skip_validate || !customer_settings.date_of_birth.required\n                  ? ''\n                  : 'required'\n              "}],staticClass:"inputs pointer",attrs:{"outlined":"","block":"","data-vv-name":"birthdate","dense":"","hide-details":"","color":"white","error-messages":_vm.errors.collect('customer-form.birthdate'),"readonly":""},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},on))]}}],null,false,491884235),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",staticClass:"inputs",attrs:{"first-day-of-week":"1","color":"primary","data-vv-name":"birthdate","scrollable":"","show-current":_vm.today,"no-title":"","max":_vm.today},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.customer.birthdate),callback:function ($$v) {_vm.$set(_vm.customer, "birthdate", $$v)},expression:"customer.birthdate"}})],1)],1):_vm._e()]},proxy:true}],null,false,703887240)}),_c('v-row',[(_vm.customer_settings.height.view)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"2"}},[_c('label',[_vm._v(_vm._s(_vm.$t("customers.height")))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:(
          !_vm.customer_settings.height.required
            ? 'between:1,2.5|max:4'
            : 'between:1,2.5|max:4|required'
        ),expression:"\n          !customer_settings.height.required\n            ? 'between:1,2.5|max:4'\n            : 'between:1,2.5|max:4|required'\n        "}],staticClass:"inputs",attrs:{"outlined":"","hide-details":"","dense":"","type":"number","step":"0.01","data-vv-validate-on":"blur","error-messages":_vm.errors.collect('customer-form.height'),"color":"secondary","data-vv-name":"height","hint":"Ejemplo: 1.75"},on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.customer.height),callback:function ($$v) {_vm.$set(_vm.customer, "height", $$v)},expression:"customer.height"}})],1):_vm._e(),(_vm.customer_settings.weight.view)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"2"}},[_c('label',[_vm._v(_vm._s(_vm.$t("customers.weight")))]),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:(
          !_vm.customer_settings.weight.required
            ? 'between:1,200|max:6'
            : 'between:1,200|max:6|required'
        ),expression:"\n          !customer_settings.weight.required\n            ? 'between:1,200|max:6'\n            : 'between:1,200|max:6|required'\n        "}],staticClass:"inputs",attrs:{"outlined":"","hide-details":"","dense":"","step":".01","type":"number","hint":"Ejemplo: 72.8","data-vv-validate-on":"blur","error-messages":_vm.errors.collect('customer-form.weight'),"color":"secondary","data-vv-name":"weight"},on:{"keypress":function($event){return _vm.isNumber($event)}},model:{value:(_vm.customer.weight),callback:function ($$v) {_vm.$set(_vm.customer, "weight", $$v)},expression:"customer.weight"}})],1):_vm._e(),(_vm.customer_settings.allergies.view)?_c('v-col',{staticClass:"py-0 pr-0",attrs:{"cols":"12","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t("customers.allergies")))]),_c('v-row',{staticStyle:{"width":"100%"}},[_c('v-col',{staticClass:"py-0 pr-0",attrs:{"cols":"4","sm":"2","md":"5"}},[_c('v-select',{directives:[{name:"validate",rawName:"v-validate",value:(
              _vm.skip_validate || !_vm.customer_settings.allergies.required
                ? ''
                : 'required'
            ),expression:"\n              skip_validate || !customer_settings.allergies.required\n                ? ''\n                : 'required'\n            "}],staticClass:"inputs",attrs:{"items":_vm.options,"single-line":"","dense":"","outlined":"","hide-details":"","data-vv-name":"options","error-messages":_vm.errors.collect('customer-form.options')},model:{value:(_vm.allergies),callback:function ($$v) {_vm.allergies=$$v},expression:"allergies"}})],1),_c('v-col',{staticClass:"py-0 pr-0",attrs:{"cols":"8","sm":"10","md":"7"}},[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:(
              !_vm.customer_settings.allergies.required ||
              _vm.allergies == 0 ||
              _vm.allergies == null
                ? 'min:2'
                : 'required|min:2'
            ),expression:"\n              !customer_settings.allergies.required ||\n              allergies == 0 ||\n              allergies == null\n                ? 'min:2'\n                : 'required|min:2'\n            "}],staticClass:"inputs",attrs:{"disabled":_vm.allergies == 0 || _vm.allergies == null,"outlined":"","dense":"","data-vv-validate-on":"blur","hide-details":"","error-messages":_vm.errors.collect('customer-form.allergies'),"color":"secondary","data-vv-name":"allergies"},model:{value:(_vm.customer.allergies),callback:function ($$v) {_vm.$set(_vm.customer, "allergies", $$v)},expression:"customer.allergies"}})],1)],1)],1):_vm._e(),(_vm.customer_settings.date_of_birth.view)?_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"6","md":"3"}},[_c('label',[_vm._v(_vm._s(_vm.$t("customers.birthdate")))]),(_vm.customer_settings.date_of_birth.view)?_c('v-menu',{ref:"menu",attrs:{"tabindex":"13","hide-details":"","close-on-content-click":false,"transition":"scale-transition","min-width":"290px","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-text-field',_vm._g({directives:[{name:"validate",rawName:"v-validate",value:(
              _vm.skip_validate || !_vm.customer_settings.date_of_birth.required
                ? ''
                : 'required'
            ),expression:"\n              skip_validate || !customer_settings.date_of_birth.required\n                ? ''\n                : 'required'\n            "}],staticClass:"inputs pointer",attrs:{"outlined":"","block":"","data-vv-name":"birthdate","dense":"","hide-details":"","color":"white","error-messages":_vm.errors.collect('customer-form.birthdate'),"readonly":""},model:{value:(_vm.computedDateFormatted),callback:function ($$v) {_vm.computedDateFormatted=$$v},expression:"computedDateFormatted"}},on))]}}],null,false,235833803),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{ref:"picker",staticClass:"inputs",attrs:{"first-day-of-week":"1","hide-details":"","color":"primary","data-vv-name":"birthdate","scrollable":"","show-current":_vm.today,"no-title":"","max":_vm.today},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.customer.birthdate),callback:function ($$v) {_vm.$set(_vm.customer, "birthdate", $$v)},expression:"customer.birthdate"}})],1):_vm._e()],1):_vm._e(),(_vm.customer_settings.nativeCity.view)?_c('v-col',{staticClass:"py-0 mt-sm-0 mb-sm-0",attrs:{"cols":"12","sm":"6","md":"2"}},[_c('label',[_vm._v(_vm._s(_vm.$t("customers.city")))]),_c('city-select',{staticClass:"inputs",attrs:{"value":_vm.customer,"valueKey":"city","validate":!_vm.customer_settings.nativeCity.required ? '' : 'required',"error_messages":_vm.errors.collect('customer-form.city'),"vv_name":"city"}})],1):_vm._e(),_vm._t("default"),(
        _vm.$route.name === 'customerEdit' ||
          _vm.$route.name === 'customerCreateWithoutDate'
      )?_c('v-col',{staticClass:"py-0 mt-5 mt-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',{domProps:{"textContent":_vm._s(_vm.$t('inventorys.description'))}}),_c('v-textarea',{staticClass:"mt-n2",staticStyle:{"border-radius":"10px"},attrs:{"rows":"3","outlined":"","hide-details":""},model:{value:(_vm.customer.description),callback:function ($$v) {_vm.$set(_vm.customer, "description", $$v)},expression:"customer.description"}})],1):_vm._e()],2),(_vm.buttons !== false)?_c('div',{staticClass:"pa-3 text-right"},[_c('v-btn',{staticClass:"mr-3",staticStyle:{"height":"25px"},attrs:{"outlined":"","elevation":"0"},on:{"click":function($event){return _vm.$router.back()}}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-btn',{staticStyle:{"width":"100px !important","color":"#363533","height":"25px","padding-top":"10px !important"},attrs:{"elevation":"0","type":"submit"}},[_c('v-icon',{staticStyle:{"margin-right":"5px"},attrs:{"size":"14px"}},[_vm._v("$save")]),_vm._v(" "+_vm._s(_vm.$t("save", { name: _vm.$t("") }))+" ")],1)],1):_vm._e()],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }